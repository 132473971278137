import {
  computed, ref, Ref, unref,
} from 'vue';
import { IAssignment } from '@/domains/assignment';
import { PlayerSessionStatus } from '@/domains/common';
import { useAlarm } from '@/composables';
import {
  IQuiz, IQuizCurrentAnswer, IQuizCurrentQuestion, IQuizQuestion, IQuizStep, QuizAnswersDisplayType, QuizAnswerStatus,
} from '../types';
import { getTimeBetweenAttempts, hasNextAttemptsTime, ITimeBetweenAttempts } from '@/helpers/time/blockingRunQuizButton';
import {
  testInfoGet,
  testSessionRun,
  testSessionQuestionsGet,
  testSessionFinish,
  testSessionAnswerSkip,
  testSessionAnswerFinish,
  testSessionAnswerUpdate,
  testSessionQuestionGet,
  testSessionCheckVersion,
} from '@/services/api/lxp-quiz-test';

interface IUseQuizSessionProps {
  playerSessionId: Ref<IAssignment['playerSessionId']>;
}

export const useQuizSession = ({ playerSessionId }: IUseQuizSessionProps) => {
  const isLoading = ref(false);
  const isQuestionLoading = ref(false);
  const isAnswerSaving = ref(false);
  const isSessionStarting = ref(false);
  const isSessionFetching = ref(false);
  const isTestFresh = ref(true);

  const session = ref<IQuiz>({
    name: '',
    description: '',
    playerSessionStatus: PlayerSessionStatus.UNKNOWN,
    questionsCount: 0,
    testTimeout: 0,
    maxAttempts: 0,
    attemptsCount: 0,
    freeFlow: true,
    nextAttemptAt: '',
    results: {
      passed: true,
      correctAnswers: 0,
      testThreshold: 0,
      answersDisplayType: QuizAnswersDisplayType.ALL,
      percent: 0,
      percentDifference: 0,
    },
  });

  const testTimeout = computed(() => session.value.testTimeout);

  const questions = ref<IQuizQuestion[]>([]);
  const questionsCount = computed(() => questions.value.length);
  const currentQuestion = ref<IQuizCurrentQuestion | undefined>();
  const currentAnswer = ref<IQuizCurrentAnswer | undefined>();
  const testStartedAt = ref<string>(new Date().toISOString());

  const isSessionUpdating = ref(false);
  const isSessionActive = computed(() => session.value.playerSessionStatus === PlayerSessionStatus.ACTIVE);
  const isSessionCompleted = computed(() => session.value.playerSessionStatus === PlayerSessionStatus.COMPLETED);
  const isSessionStarted = computed(() => isSessionActive.value || isSessionCompleted.value);

  const isPassingStrictMode = computed(() => !session.value.freeFlow);
  const testTimeoutInSeconds = computed(() => session.value.testTimeout * 60);
  const currentResultDifference = computed(() => session.value.results?.percentDifference ?? 0);
  const hasAttempts = computed(() => session.value.maxAttempts > 1
    && session.value.attemptsCount < session.value.maxAttempts);
  const showPassAgainButton = computed(() => {
    return session.value.results!.percent < 1 && (session.value.attemptsCount < session.value.maxAttempts
      || session.value.maxAttempts === 0);
  });
  const timeBetweenAttempts = computed<ITimeBetweenAttempts>(() =>
    getTimeBetweenAttempts(session.value?.nextAttemptAt ?? ''));
  const hasNextAttemptsTimeFlag = computed(() => hasNextAttemptsTime(timeBetweenAttempts.value));

  /* Buisness logic */
  const { isTimeOver, startTimer, stopTimer } = useAlarm({
    startedAt: testStartedAt,
    maxSecondsCount: testTimeoutInSeconds,
  });

  const isAnsweredQuestion = ref(false);

  const currentQuestionIndex = computed(() =>
    questions.value.findIndex(
      (question) => currentQuestion.value?.id === question.id,
    ));
  const prevQuestion = computed(() => questions.value.at(currentQuestionIndex.value - 1));
  const nextQuestion = computed(() => questions.value.at(currentQuestionIndex.value + 1));

  const unansweredQuestionsCount = computed<number>(() => {
    return questions.value.filter((question) => question.answerStatus === QuizAnswerStatus.ACTIVE).length;
  });

  const isTestTimeLimited = computed(() => testTimeout.value > 0);
  const isTestTimeExpired = computed(() => isTimeOver.value);
  const isQuestionAvailable = computed(() => !(isTestTimeLimited.value && isTestTimeExpired.value));

  const isLastUnansweredQuestion = computed(() => unansweredQuestionsCount.value === 1);
  const isUnansweredQuestionsExists = computed(() => {
    return !(
      unref(questions)
        .filter((question) => !question.current)
        .every((question) =>
          [
            QuizAnswerStatus.COMPLETED,
            QuizAnswerStatus.SKIPPED,
          ].includes(question.answerStatus)) && isAnsweredQuestion.value
    );
  });

  const isCurrentQuestionIsLastUnansweredQuestion = computed(
    () =>
      isLastUnansweredQuestion.value
      && questions.value[currentQuestionIndex.value].answerStatus === QuizAnswerStatus.ACTIVE,
  );
  const isLastQuestion = computed(() => currentQuestionIndex.value === questionsCount.value - 1);
  const isFirstQuestion = computed(() => currentQuestionIndex.value === 0);

  const isPrevQuestionButtonVisible = computed(() => !isPassingStrictMode.value);
  const isPrevQuestionButtonDisabled = computed(
    () => isFirstQuestion.value || isQuestionLoading.value || isAnswerSaving.value,
  );
  const isNextQuestionButtonVisible = computed(() => !isLastQuestion.value);
  const isNextQuestionButtonDisabled = computed(
    () => isLastQuestion.value || isQuestionLoading.value || isAnswerSaving.value,
  );

  const fetchSession = async () => {
    if (playerSessionId.value === null) throw new ReferenceError('playerSessionId is not defined');

    isSessionFetching.value = true;

    try {
      const response = await testInfoGet({
        playerSessionId: playerSessionId.value,
      });

      session.value = response;
    } finally {
      isSessionFetching.value = false;
    }
  };

  const fetchQuestions = async () => {
    if (playerSessionId.value === null) throw new ReferenceError('playerSessionId is not defined');

    isQuestionLoading.value = true;

    try {
      const response = await testSessionQuestionsGet({
        params: {
          playerSessionId: playerSessionId.value,
        },
      });

      questions.value = response.questions;
      currentQuestion.value = response.firstActiveQuestion;
      currentAnswer.value = response.firstActiveAnswer;
      testStartedAt.value = response.startedAt;

      session.value.playerSessionStatus = response.playerSessionStatus;
      session.value.freeFlow = response.freeFlow;
      session.value.testTimeout = response.testTimeout;
    } finally {
      isQuestionLoading.value = false;
    }
  };

  const fetchQuestion = async ({ questionId }: { questionId: number }) => {
    if (playerSessionId.value === null) {
      throw new ReferenceError('playerSessionId is not defined');
    }

    if (typeof questionId === 'undefined') {
      throw new ReferenceError('questionId should be defined');
    }

    isQuestionLoading.value = true;

    try {
      const response = await testSessionQuestionGet({
        params: {
          playerSessionId: playerSessionId.value,
          questionId,
        },
      });

      questions.value = response.questions;
      currentQuestion.value = response.currentQuestion;
      currentAnswer.value = response.currentAnswer;
      testStartedAt.value = response.startedAt;

      session.value.playerSessionStatus = response.playerSessionStatus;
      session.value.freeFlow = response.freeFlow;
      session.value.testTimeout = response.testTimeout;
    } finally {
      isQuestionLoading.value = false;
    }
  };

  const runSession = async () => {
    if (playerSessionId.value === null) throw new ReferenceError('playerSessionId is not defined');

    isSessionStarting.value = true;

    try {
      const request = {
        params: {
          playerSessionId: playerSessionId.value,
        },
      };

      const { playerSessionStatus } = await testSessionRun(request);

      if ([PlayerSessionStatus.NEW, PlayerSessionStatus.ACTIVE].includes(playerSessionStatus)) {
        return { quizStep: IQuizStep.PLAYER };
      }

      return { quizStep: IQuizStep.RESULT };
    } catch (e: any) {
      if (e.response.status === 409) {
        return { quizStep: IQuizStep.RESULT };
      }

      return { quizStep: IQuizStep.INTRO };
    } finally {
      isSessionStarting.value = false;
    }
  };

  const finishSession = async () => {
    if (playerSessionId.value === null) throw new ReferenceError('playerSessionId is not defined');

    isLoading.value = true;

    const params = {
      playerSessionId: playerSessionId.value,
    };

    try {
      await testSessionFinish({ params });
    } finally {
      isLoading.value = false;
    }
  };

  const skipQuestion = async () => {
    if (typeof playerSessionId.value === 'undefined' || playerSessionId.value === null) {
      throw new ReferenceError('playerSessionId should be defined');
    }

    if (typeof currentAnswer.value === 'undefined') {
      throw new ReferenceError('currentAnswer should be defined');
    }

    isAnswerSaving.value = true;

    try {
      const params = {
        answerId: currentAnswer.value?.id,
        playerSessionId: playerSessionId.value,
      };

      await testSessionAnswerSkip({ params });
    } finally {
      isAnswerSaving.value = false;
    }
  };

  const finishQuestion = async () => {
    if (typeof playerSessionId.value === 'undefined' || playerSessionId.value === null) {
      throw new ReferenceError('playerSessionId should be defined');
    }

    if (typeof currentAnswer.value === 'undefined') {
      throw new ReferenceError('currentAnswer should be defined');
    }

    isAnswerSaving.value = true;

    try {
      const params = {
        playerSessionId: playerSessionId.value,
        answerId: currentAnswer.value.id,
      };

      await testSessionAnswerFinish({
        params,
      });
    } finally {
      isAnswerSaving.value = false;
    }
  };

  const updateAnswer = async ({ answer }: { answer: Record<string, any> }) => {
    if (typeof playerSessionId.value === 'undefined' || playerSessionId.value === null) {
      throw new ReferenceError('playerSessionId should be defined');
    }

    if (typeof currentAnswer.value === 'undefined') {
      throw new ReferenceError('currentAnswer should be defined');
    }

    try {
      isAnswerSaving.value = true;

      const params = {
        answerId: currentAnswer.value.id,
        playerSessionId: playerSessionId.value,
      };

      const payload = {
        result: answer,
      };

      await testSessionAnswerUpdate({
        params,
        payload,
      });
    } finally {
      isAnswerSaving.value = false;
    }
  };

  const checkNewVersion = async () => {
    if (typeof playerSessionId.value === 'undefined' || playerSessionId.value === null) {
      throw new ReferenceError('playerSessionId should be defined');
    }

    try {
      const { hasNewVersion = false } = await testSessionCheckVersion({
        playerSessionId: playerSessionId.value,
      });

      isTestFresh.value = !hasNewVersion;
    } catch (e) {
      console.error(e);
    }
  };

  const init = async ({ questionId }: { questionId?: number }) => {
    const onFetchCompletePromise = Promise.all([
      fetchSession(),
      questionId ? fetchQuestion({ questionId }) : fetchQuestions(),
    ]);

    onFetchCompletePromise.then(() => {
      startTimer();
    })
      .catch((e: unknown) => {
        console.error(e);

        stopTimer();

        throw e;
      })
      .finally(() => {
        isLoading.value = false;
      });

    return onFetchCompletePromise;
  };

  return {
    isLoading,
    isQuestionLoading,
    isAnswerSaving,

    session,
    testTimeout,
    testStartedAt,

    testTimeoutInSeconds,
    timeBetweenAttempts,
    hasNextAttemptsTimeFlag,
    currentResultDifference,
    showPassAgainButton,
    isPassingStrictMode,
    isCurrentQuestionIsLastUnansweredQuestion,
    isPrevQuestionButtonVisible,
    isPrevQuestionButtonDisabled,
    isNextQuestionButtonVisible,
    isNextQuestionButtonDisabled,
    isQuestionAvailable,
    isUnansweredQuestionsExists,
    isAnsweredQuestion,
    isFirstQuestion,
    isLastQuestion,
    isTimeOver,
    isTestTimeLimited,
    isTestTimeExpired,
    isTestFresh,
    isSessionStarting,
    isSessionFetching,
    isSessionUpdating,
    isSessionActive,
    isSessionCompleted,
    isSessionStarted,
    hasAttempts,

    fetchSession,
    runSession,
    finishSession,
    checkNewVersion,

    questions,
    questionsCount,
    fetchQuestions,

    currentQuestion,
    fetchQuestion,

    prevQuestion,
    nextQuestion,

    currentAnswer,
    skipQuestion,
    finishQuestion,
    updateAnswer,

    init,
  };
};
